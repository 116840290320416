import React, { useState, useEffect, useRef } from "react"
import FAQ from "./Faq"
import Image from 'next/future/image'
import ThreeInOne from './ThreeInOne'
import TestimonialSlider from './TestimonialSlider'
import StorySection from './StorySection'
import SocialProof from './SocialProof'

const MarketingContent = ({ style, roomName, showHeaderSection, setShowHeaderSection, isStartpage }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [sliderPosition, setSliderPosition] = useState(70)
  const containerRef = useRef(null)
  
  const slides = [
    {
      before: "/assets/before-after-header/kitchen-before.jpeg",
      after: "/assets/before-after-header/kitchen-after.jpg",
      alt: "Kitchen",
      label: "Kitchen"
    },
    {
      before: "/assets/before-after-header/living-room-before.jpeg",
      after: "/assets/before-after-header/living-room-after2.jpg",
      alt: "Living Room",
      label: "Living Room"
    },
    {
      before: "/assets/before-after-header/bedroom-before.jpeg",
      after: "/assets/before-after-header/bedroom-after.jpg",
      alt: "Bedroom",
      label: "Bedroom"
    },
    {
      before: "/assets/before-after-header/bathroom-before3.jpg",
      after: "/assets/before-after-header/bathroom-after3.jpg",
      alt: "Bathroom",
      label: "Bathroom"
    }
  ]

  const handleDragStart = (e) => {
    e.preventDefault()
    setIsDragging(true)
    
    // Add cursor styling to body during drag
    document.body.style.cursor = 'grabbing'
  }

  const handleDrag = (e) => {
    if (!isDragging || !containerRef.current) return

    const containerRect = containerRef.current.getBoundingClientRect()
    const x = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX
    const containerX = x - containerRect.left
    const newPosition = (containerX / containerRect.width) * 100

    setSliderPosition(Math.max(0, Math.min(100, newPosition)))
  }

  const handleDragEnd = () => {
    setIsDragging(false)
    // Reset cursor
    document.body.style.cursor = ''
  }

  useEffect(() => {
    // Auto-slide functionality with slower timing (10 seconds)
    if (!isDragging) {
      const autoSlideRef = setInterval(() => {
        setActiveSlide(prev => (prev < slides.length - 1 ? prev + 1 : 0))
      }, 10000)

      return () => {
        clearInterval(autoSlideRef)
      }
    }
  }, [isDragging])

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDrag)
      document.addEventListener('mouseup', handleDragEnd)
      document.addEventListener('touchmove', handleDrag)
      document.addEventListener('touchend', handleDragEnd)

      return () => {
        document.removeEventListener('mousemove', handleDrag)
        document.removeEventListener('mouseup', handleDragEnd)
        document.removeEventListener('touchmove', handleDrag)
        document.removeEventListener('touchend', handleDragEnd)
      }
    }
  }, [isDragging, activeSlide])

  return (
    <>
      <section className={`relative ${showHeaderSection ? "" : "hidden"}`}>
        {/* Main Content Container */}
        <div className="container-fluid relative">
          <div className="flex flex-col max-w-[2500px] px-0">
            {/* Main Before/After Slider */}
            <div className="flex-1 min-w-0 mb-4 relative">
              <div className="relative w-full aspect-[15/8] max-h-[1300px] overflow-hidden">
                <div 
                  ref={containerRef}
                  className="relative h-full w-full"
                >
                  <div 
                    key={activeSlide} 
                    className="absolute inset-0 animate-fade-in pointer-events-none"
                    style={{
                      animation: 'fadeIn 700ms ease-in-out'
                    }}
                  >
                    <Image
                      src={slides[activeSlide].after}
                      alt={`After ${slides[activeSlide].alt}`}
                      className="absolute inset-0 w-full h-full object-cover"
                      width={2560}
                      height={1600}
                      priority
                    />
                    <Image
                      src={slides[activeSlide].before}
                      alt={`Before ${slides[activeSlide].alt}`}
                      className="absolute inset-0 w-full h-full object-cover"
                      width={2560}
                      height={1600}
                      style={{
                        clipPath: `inset(0 0 0 ${sliderPosition}%)`
                      }}
                    />
                  </div>

                  {/* Overlay gradient for large screens */}
                  <div className="hidden lg:block absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
                  
                  {/* Labels with higher z-index */}
                  <div className="absolute top-4 left-4 bg-white bg-opacity-70 text-gray-900 px-3 py-1 rounded-full text-sm font-medium shadow-sm backdrop-blur-sm z-[5]">
                    After
                  </div>
                  <div className="absolute top-4 right-4 bg-white bg-opacity-70 text-gray-900 px-3 py-1 rounded-full text-sm font-medium shadow-sm backdrop-blur-sm z-[5]">
                    Before
                  </div>

                  {/* Header content for large screens */}
                  <div className="hidden lg:block absolute bottom-0 left-0 right-0 z-[5] p-8">
                    <div className="max-w-4xl mx-auto text-center">
                      <h2 className="text-5xl font-heading leading-[1.1] tracking-[-0.02em] mb-4 text-white">See Your Room Transform</h2>
                      <p className="text-lg text-white/90 font-light max-w-2xl mx-auto mb-6">
                        Upload a photo and get instant redesigns, virtual staging, or fresh inspiration with MyRoom Designer.AI.
                      </p>
                      <div className="flex justify-center items-center gap-6">
                        <button
                          onClick={() => {
                            setShowHeaderSection(false)
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                          }}
                          className="py-3 px-8 bg-white hover:bg-gray-100 text-black rounded-full font-heading text-base hover:shadow-lg transition-all duration-300"
                        >
                          Get Started
                        </button>
                        <a
                          href="#upload-and-redesign"
                          className="text-white hover:text-white/80 text-base font-light flex items-center group"
                        >
                          <span>Learn how it works</span>
                          <svg className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M9 5l7 7-7 7" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Slider Handle - Centered */}
                  <div 
                    className="absolute inset-y-0 flex items-center z-[5] cursor-ew-resize group transform -translate-x-1/2"
                    style={{ left: `${sliderPosition}%` }}
                    onMouseDown={handleDragStart}
                    onTouchStart={handleDragStart}
                  >
                    {/* Vertical Line */}
                    <div className="w-0.5 h-full bg-white group-hover:bg-white/90 transition-colors">
                      {/* Handle Button - Centered on Line */}
                      <div className="absolute top-1/2 left-1/2 w-8 h-8 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white flex justify-center items-center cursor-grab active:cursor-grabbing shadow-lg hover:bg-white/90 hover:scale-110 transition-all duration-200">
                        <div className="flex items-center gap-1">
                          <svg 
                            className="w-3 h-3 text-gray-700"
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M15 19l-7-7 7-7" 
                            />
                          </svg>
                          <svg 
                            className="w-3 h-3 text-gray-700"
                            fill="none"
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Thumbnails Section */}
            <div className="w-full relative z-[5] mb-4">
              <div className="mx-auto max-w-[1400px] px-4 md:px-0">
                <div className="flex gap-3 pt-1 pb-2 px-1 items-center md:justify-center overflow-x-auto scrollbar-hide">
                  {slides.map((slide, index) => (
                    <div key={index} className="flex-shrink-0 w-[120px] md:w-[180px] lg:w-[220px]">
                      <button
                        onClick={() => setActiveSlide(index)}
                        className="group flex flex-col items-center w-full relative"
                      >
                        <div className={`relative w-[120px] md:w-[180px] lg:w-[220px] h-[90px] md:h-[135px] lg:h-[165px] overflow-hidden rounded-lg shadow-md ${
                          index === activeSlide ? 'ring-1 ring-gray-700 ring-offset-2' : ''
                        }`}>
                          <Image
                            src={slide.after}
                            alt={slide.alt}
                            className={`w-full h-full transition-all duration-500 ease-in-out group-hover:scale-110 ${
                              slide.label === "Bathroom" ? "object-cover bg-gray-50" : "object-cover"
                            }`}
                            width={220}
                            height={165}
                          />
                          <div className={`absolute inset-0 bg-black transition-all duration-500 ease-in-out ${
                            index === activeSlide ? 'bg-opacity-0' : 'bg-opacity-20 group-hover:bg-opacity-10'
                          }`} />
                          <div className="absolute bottom-0 left-0 right-0 bg-white/70 backdrop-blur-[2px] py-1.5 px-2">
                            <p className="text-xs md:text-sm font-medium text-center text-gray-900">
                              {slide.label}
                            </p>
                          </div>
                        </div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Header Section - Only visible on mobile/tablet */}
            <div className="lg:hidden bg-gradient-to-t from-[#f7f6f4] via-[#f7f6f4]/95 to-transparent pt-3 pb-6">
              <div className="container mx-auto px-4">
                <div className="max-w-4xl mx-auto text-center">
                  <h2 className="text-[2rem] md:text-4xl font-heading leading-[1.1] tracking-[-0.02em] mb-2">See Your Room Transform</h2>
                  <p className="text-sm lg:text-lg text-gray-600 font-light max-w-2xl mx-auto mb-4 px-4">
                    Upload a photo and get instant redesigns, virtual staging, or fresh inspiration with MyRoom Designer.AI.
                  </p>
                  <div className="flex flex-col sm:flex-row justify-center items-center gap-3 sm:space-x-6 px-4">
                    <button
                      onClick={() => {
                        setShowHeaderSection(false)
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }}
                      className="w-full sm:w-auto py-2 px-6 bg-[#2C2C2C] hover:bg-black text-white rounded-full font-heading text-sm sm:text-base hover:shadow-lg transition-all duration-300"
                    >
                      Get Started
                    </button>
                    <a
                      href="#upload-and-redesign"
                      className="text-gray-900 hover:text-black text-sm sm:text-base font-light flex items-center group"
                    >
                      <span>Learn how it works</span>
                      <svg className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M9 5l7 7-7 7" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

</section>

  <SocialProof />

  <section className="relative overflow-hidden pb-44 pt-20 bs-section-dragged">
          <div className="relative z-10 container mx-auto px-4">
            <p className="mb-5 font-heading font-medium text-xs tracking-wider text-transparent bg-clip-text bg-gradient-cyan uppercase text-center">OUR TIPS</p>
            <h2 className="mb-24 text-center font-heading text-4xl md:text-5xl text-gray-800">Did You Know?</h2>
            <div className="md:mx-auto md:max-w-6xl">
              <div className="flex flex-wrap -m-5">
                <div className="w-full md:w-1/2 p-5 md:mt-auto">
                  <div className="p-9 transform hover:-translate-y-3 rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4]">
                    <video className="mx-auto w-full h-full object-cover" loop muted autoPlay>
                      <source src="https://moodboardai.com/assets/did-you-know/Upscale.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <div></div>
                    <h3 className="font-heading text-3xl mt-6 pl-6 mb-4">
                      <span style={{ fontSize: '1.75rem', fontWeight: 'inherit', backgroundColor: 'rgb(247 246 244 / var(--tw-bg-opacity))', color: 'rgba(0,0,0,var(--tw-text-opacity))' }}></span>
                      <span style={{ fontSize: '1.75rem', fontWeight: 'inherit', backgroundColor: 'rgb(247 246 244 / var(--tw-bg-opacity))', color: 'rgba(0,0,0,var(--tw-text-opacity))' }}>Easy Upscale and more</span>
                    </h3>
                    <p className="font-heading text-base text-opacity-70 pl-6">You can upscale your photo for high-resolution results, or remove unwanted items.</p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <div className="flex flex-col justify-between transform hover:-translate-y-3 overflow-hidden rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4] p-9">
                    <div className="pb-0 mb-4 p-0">
                      <p className="mb-4 font-heading text-base text-opacity-70 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lightbulb mr-2 text-yellow-500 animate-pulse" viewBox="0 0 16 16">
                          <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a2 2 0 0 0-.453-.618A5.98 5.98 0 0 1 2 6m6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1" />
                        </svg>
                        Pro Tip
                      </p>
                      <h3 className="mb-5 font-heading text-3xl"></h3>
                      <h3 className="font-heading text-3xl mb-6 w-3/4">You Can Integrate Top Designer Styles</h3>
                      <div className="mb-5">
                        <img src="/assets/did-you-know/MyRoomDesignerAI-result1.jpg" alt="" />
                      </div>
                      <p className="mt-6 font-heading mb-4">Dreaming of a room styled like Magnolia, Becki Owens, or Studio McGee? With <a href="https://myroomdesigner.ai/interior-design-ai/designer" className="text-pink-700 hover:text-pink-800 font-bold">MyRoomDesigner.AI</a>, simply enter your favorite designer's name in the chat, click "Change prompt", and the AI will craft a stunning space tailored to your aesthetic.</p>
                      <div className="flex space-x-4 mt-6">
                        <button
                          onClick={() => {
                            setShowHeaderSection(false)
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                          }}
                          className="w-full sm:w-auto py-2 px-6 bg-[#2C2C2C] hover:bg-black text-white rounded-full font-heading text-sm sm:text-base hover:shadow-lg transition-all duration-300"
                        >
                          Design your room
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <div className="flex flex-col justify-between transform hover:-translate-y-3 overflow-hidden rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4] p-4 p-9">
                    <div className="pb-0 p-9 mb-4">
                      <h3 className="mb-5 font-heading text-3xl">Explore Real-Life Products</h3>
                      <p className="mt-6 font-heading mb-2">You can find similar products based on what's in your photo with ease. Simply click on the <strong>"Find Similar"</strong> button, then select the item in your photo that you want to match.</p>
                    </div>
                    <video className="mx-auto w-full h-full object-cover px-4 pb-6" loop muted autoPlay>
                      <source src="/assets/did-you-know/Find-Similar-Products-MyRoomDesigner-AI.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <div className="p-9 transform hover:-translate-y-3 rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4]">
                    <img className="mx-auto" src="/assets/did-you-know/Mood-Board-Editor-with-Shopping-List-Creator.png" alt="" />
                    <div className="pb-0 p-6 mb-6">
                      <h3 className="mb-5 font-heading text-3xl">Customize freely</h3>
                      <p className="mb-4 font-heading text-base text-opacity-70">You can import your AI-generated photos into the <a href="https://myroomdesigner.ai/mood-board-editor" className="text-pink-700 hover:text-pink-800 font-bold">Mood Board Editor</a> for further customization. Explore powerful features like:</p>
                    </div>
                    <ul className="inline-block max-w-sm justify-center text-center ml-6">
                      <li className="flex items-center font-heading mb-3 font-medium text-base text-gray-900 text-center md:text-left">
                        <svg className="mr-2.5 shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="#14B082" />
                        </svg>
                        <p>Shopping List Creator</p>
                      </li>
                      <li className="flex items-center font-heading mb-3 font-medium text-base text-gray-900 text-center md:text-left">
                        <svg className="mr-2.5 shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="#14B082" />
                        </svg>
                        <p>Background Remover</p>
                      </li>
                      <li className="flex items-center font-heading mb-3 font-medium text-base text-gray-900 text-center md:text-left">
                        <svg className="mr-2.5 shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="#14B082" />
                        </svg>
                        <p>One-click Import of Favorites</p>
                      </li>
                      <li className="flex items-center font-heading mb-3 font-medium text-base text-gray-900 text-center md:text-left">
                        <svg className="mr-2.5 shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="#14B082" />
                        </svg>
                        <p>Crop, Flip, and Duplicate Items</p>
                      </li>
                      <li className="flex items-center font-heading font-medium text-base text-gray-900 text-center md:text-left">
                        <svg className="mr-2.5 shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="#14B082" />
                        </svg>
                        <p>Find Similar Products</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

  <section className="mx-auto bg-[#f7f6f4] bs-section-dragged px-8 py-36 lg:py-44 lg:p-44">
    <div className="container mx-auto">
  <h2 id="upload-and-redesign" className="font-heading sm:text-7xl md:text-9xl xl:text-10xl mb-24 text-4xl text-center md:text-left">How My Room Designer works</h2>
  {/* Step 1 */}
  <div className="flex flex-col md:flex-row items-center mb-20 xl:mb-6">
    <div className="w-full md:w-1/12 mb-4 md:mb-0">
      <p className="font-heading text-4xl text-center md:text-left">01</p>
              </div>
    <div className="w-full md:w-5/12 mb-4 md:mb-0 text-center md:text-left">
      <h2 className="mb-4 font-heading text-xl md:text-3xl md:font-bold font-bold text-center md:text-left">Upload Your Room Photo</h2>
      <Image
        className="rounded-lg w-full object-cover md:mb-0 md:mr-4 transform ease-out hover:translate-y-3 duration-1000 transition md:hidden mb-6"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step1-My-Room-Designer-AI.png"
        alt="Interior Design Inspiration"
        width={2000}
        height={2000}
      />
      <p className="w-10/12 font-heading mb-6 text-gray-600 text-center md:text-left mx-auto md:-mx-0">Start by choosing either "Inspiration" for fresh ideas or "Restyling" to reimagine your actual room. Select your room category, and then sit back as the AI analyzes and describes your photo, laying the perfect foundation for your design journey.</p>
      <a href="/interior-design-ai/designer" className="group relative inline-flex font-heading py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2  ring-2 ring-gray-700 hover:ring-gray-500 ">
        <p className>Go to designer</p>
                </a>
              </div>
    <div className="hidden md:block w-full md:w-6/12 lg:pl-8 lg:pl-10">
      <Image
        className="rounded-lg w-full object-cover rounded-3xl transform ease-out hover:translate-y-3 duration-1000 transition"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step1-My-Room-Designer-AI.png"
        alt="Interior Design Inspiration"
        width={2000}
        height={2000}
      />
              </div>
              </div>
  {/* Step 2 */}
  <div className="flex flex-col md:flex-row items-center mb-20 xl:mb-28 text-center md:text-left">
    <div className="w-full md:w-1/12 mb-4 md:mb-0">
      <p className="font-heading text-4xl ">02</p>
              </div>
    <div className="w-full md:w-5/12 mb-4 md:mb-0">
      <h2 className="mb-4 font-heading text-xl md:text-3xl md:font-bold font-bold text-center md:text-left">Add Your Preferences</h2>
      <Image
        className="rounded-lg w-full object-cover shadow-xl md:mb-0 md:mr-4 rounded-xl transform ease-out hover:translate-y-3 duration-1000 transition md:hidden mb-6"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/add-prompt.gif"
        alt="Collaboration with Designer"
        width={2000}
        height={2000}
      />
      <p className="text-gray-600 w-10/12 font-heading mb-6 text-center md:text-left mx-auto md:mx-0">Select your preferred style and add details to customize. Feel free to chat with the AI to swap furniture items, adjust colors, or modify any aspect you desire. Easily personalize your prompt by clicking the 'Change Prompt' button or manually rewriting it for a more tailored touch.</p>
      <a href="/interior-design-ai/designer" className="group relative inline-flex font-heading  py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2  ring-2 ring-gray-700 hover:ring-gray-500 ">
        <p className>Go to designer</p>
                </a>
              </div>
    <div className="hidden md:block md:w-6/12 lg:pl-8">
      <Image
        className="rounded-lg w-full object-cover shadow-xl rounded-xl transform ease-out hover:translate-y-3 duration-1000 transition"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/add-prompt.gif"
        alt="Collaboration with Designer"
        width={2000}
        height={2000}
      />
              </div>
              </div>
  {/* Step 3 */}
  <div className="flex flex-col md:flex-row items-center text-center md:text-left">
    <div className="w-full md:w-1/12 mb-4 md:mb-0">
      <p className="font-heading text-4xl text-center md:text-left">03</p>
              </div>
    <div className="w-full md:w-5/12 mb-4 md:mb-0">
      <h2 className="mb-4 font-heading text-xl md:text-3xl md:font-bold font-bold text-center md:text-left">Receive Your Design</h2>
      <Image
        className="rounded-lg w-full object-cover shadow-xl md:mb-0 md:mr-4 rounded-3xl transform ease-out hover:translate-y-3 duration-1000 transition md:hidden mb-6"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step3-Create-Similar.png"
        alt="Final Interior Design"
        width={2000}
        height={2000}
      />
      <p className="text-gray-600 w-10/12 font-heading mb-6 text-center md:text-left mx-auto md:mx-0">Instantly receive three personalized design options, and with just one click, generate similar alternatives to explore even more possibilities. You can upscale your photo for high-resolution results, remove unwanted items, find similar products based on what's in your photo, or create a shopping list in our editor.</p>
      <a href="/interior-design-ai/designer" className="group relative inline-flex py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2  ring-2 ring-gray-700 hover:ring-gray-500 ">
        <p className>Start your room makeover</p>
                </a>
              </div>
    <div className="hidden md:block w-full md:w-6/12 lg:pl-8">
      <Image
        className="rounded-lg w-full object-cover shadow-xl transform ease-out hover:translate-y-3 duration-1000 transition rounded-xl"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step3-Create-Similar.png"
        alt="Final Interior Design"
        width={2000}
        height={2000}
      />
              </div>
              </div>
              </div>
</section>




     
        <ThreeInOne />

        <TestimonialSlider />

        <StorySection />

        <FAQ
          faqs={[
            {
              question: `How does the Interior Design AI create ${style} ${roomName.toLowerCase()} designs?`,
              answer: `Our Interior Design AI uses advanced machine learning algorithms to analyze your uploaded ${roomName.toLowerCase()} photos or sketches. It identifies key elements such as layout, furniture placement, and color schemes. Based on the input, it generates multiple ${style} design options tailored to your preferences. You can refine the results by adjusting details like room style, color palette, and furniture preferences. The AI leverages design principles to ensure your ${roomName.toLowerCase()} is both aesthetically pleasing and functional.`,
            },
            {
              question: `Can I design various styles besides ${style} for my ${roomName.toLowerCase()}?`,
              answer: `Yes, you can! While we excel at creating ${style} designs, MyRoomDesigner.AI offers many other styles including Modern, Rustic, Industrial, and more. You can also incorporate top design styles like Love Magnolia, Becki Owens, Studio McGee, or Shoppe Interiors. Simply describe the changes you'd like to make to your ${roomName.toLowerCase()}, click "Change Prompt," and the tool will adjust the description to reflect your desired style. This allows you to create a room that matches your personal vision with ease.`,
            },
            {
              question: `How closely will the AI designs match my current ${roomName.toLowerCase()}?`,
              answer: `Our AI accurately scans and analyzes your ${roomName.toLowerCase()}'s layout and materials from your photos. It then generates ${style} designs that closely match the style and structure of your existing space. You can further refine and adjust the design by manually editing the description or using the "Change prompt" button in the chat option to add specific details, such as "add black curtain rod and beige linen curtains" or "swap the furniture." This allows you to customize the ${roomName.toLowerCase()} design to perfectly match your vision.`,
            },
            {
              question: `Can I find the items from the generated ${style} ${roomName.toLowerCase()} design?`,
              answer: `Yes, you can! Simply click on the "Find similar products" button, and by selecting the items within your ${roomName.toLowerCase()} photo, you'll be able to view similar real-life products that match the ${style} style.`,
            },
            {
              question: `How customizable are the ${style} designs generated by MyRoomDesigner.AI?`,
              answer: `The ${style} designs generated for your ${roomName.toLowerCase()} are highly customizable! You can adjust colors, swap furniture, and tweak layouts to your preference. Our tool also offers several advanced features such as upscaling your favorite image to high resolution, cleaning up photos, removing unwanted items, and creating similar designs. Additionally, you can add your ${roomName.toLowerCase()} design to the Mood Board Designer as a reference photo, build a mood board, and even generate a shopping list based on the design!`,
            },
            {
              question: `How can interior designers use this tool for their ${roomName.toLowerCase()} projects?`,
              answer: `With MyRoomDesigner.AI, MoodBoard AI, and Board Editor, interior designers can easily create multiple ${style} ${roomName.toLowerCase()} concepts, mood boards, shopping lists, and color palettes. The tool also allows for the creation of client-ready presentations, helping designers enhance their creative process, streamline their workflow, and present polished, professional ${roomName.toLowerCase()} designs to clients.`,
            },
            {
              question: "How do I cancel my subscription?",
              answer: "Manage your subscription through your dashboard. Cancellation will take effect at the end of your billing period.",
            },
            {
              question: "Can I change my subscription plan for MyRoomDesigner.AI?",
              answer: `Yes, you can upgrade or downgrade anytime via the Billing section in your account. This gives you flexibility in how many ${style} ${roomName.toLowerCase()} designs you can create each month.`,
            },
            {
              question: "Is my data secure when using MyRoomDesigner.AI?",
              answer: `Your ${roomName.toLowerCase()} photos and designs are encrypted and private. We don't share data with third parties, and you can delete it anytime via account settings.`,
            },
            {
              question: "Can I get a refund for the AI room design service?",
              answer: `Refunds aren't offered due to the immediate GPU resource consumption required for generating ${style} ${roomName.toLowerCase()} designs. However, we recommend starting with our monthly-paid Personal subscription, which you can cancel at any time. This way, you can try the service risk-free and decide if it fits your needs.`,
            },
          ]}
        />
    </>
  )
}

export default MarketingContent